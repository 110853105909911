import React from 'react'

import Link from 'gatsby-link'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import CustomSVG from '../../components/custom-svg'

const styles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    textDecoration: 'none',

    '& > .MuiCard-root': {
      color: theme.palette.primary.contrastText,
      width: '100%',
      backgroundColor: props =>
        props.blue
          ? theme.palette.primary.main
          : props.green
          ? theme.palette.success.main
          : props.yellow
          ? theme.palette.yellow.primary
          : props.orange
          ? theme.palette.orange.primary
          : props.purple
          ? theme.palette.purple.primary
          : props.blackSolid
          ? theme.palette.black.primary
          : props.black
          ? 'rgba(0, 0, 0, 0.35)'
          : null,

      padding: theme.spacing(2)
    },

    '& + .CardLink-root': {
      marginTop: theme.spacing(2)
    }
  },

  card: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    minHeight: '110px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '140px'
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '160px'
    }
  },

  icon: {
    display: 'table',
    width: '100%',

    '& svg': {
      width: '30px',
      height: '30px',
      float: 'right',
      opacity: props => (props.iconSemiTransparent ? '0.5' : '1'),
      [theme.breakpoints.up('sm')]: {
        width: '37px',
        height: '37px'
      },

      '& *': {
        fill: theme.palette.primary.contrastText
      }
    }
  },

  title: {
    alignSelf: 'flex-end',
    width: '100%',
    paddingRight: '20px',
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px'
    },
    // for Quick Fact text
    '@media (min-width:335px)': {
      paddingRight: '40px'
    },
    '@media (min-width:400px)': {
      paddingRight: '60px'
    }
  },

  description: {
    marginTop: theme.spacing(1),
    paddingRight: '22px'
  },

  goToLink: {
    float: 'right',
    position: 'absolute',
    bottom: theme.spacing(1.75),
    right: theme.spacing(1)
  },

  hpHover: {
    '& > .MuiCard-root:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      '& svg': {
        opacity: '1'
      }
    },

    '& > .MuiCard-root:focus': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      '& svg': {
        opacity: '1'
      }
    }
  }
}))

export default function cardLink(props) {
  const classes = styles(props)
  const icon = props.icon ? <CustomSVG content={props.icon.iconSource} /> : null
  const hoverClass = props.black ? classes.hpHover : null

  return (
    <Link
      className={[classes.root, 'CardLink-root', hoverClass].join(' ')}
      to={props.to ? props.to : ''}
    >
      <Card className={classes.card}>
        {icon ? <div className={classes.icon}>{icon}</div> : null}

        {props.title ? (
          <Typography className={classes.title} variant="h2" color="inherit">
            {props.title}
          </Typography>
        ) : null}

        {props.description ? (
          <Typography className={classes.description} variant="body2" color="inherit">
            {props.description}
          </Typography>
        ) : null}

        {props.to && props.showArrow === 'true' ? (
          <NavigateNextIcon className={classes.goToLink} />
        ) : null}
      </Card>
    </Link>
  )
}
