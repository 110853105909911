import React from 'react'
import Link from 'gatsby-link'

import CustomSVG from './custom-svg'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Container, Typography } from '@material-ui/core'

const styles = makeStyles(theme => ({
  footerInfoWrapper: {
    alignSelf: 'flex-end',
    width: '100%',
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.5),
      width: `calc(100% + ${theme.spacing(5)}px)`
    }
  },
  footerInfoSnackbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    color: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      borderRadius: '4px'
    }
  },
  footerInfoBtnLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      order: -1 // swap button and logo
    }
  },
  footerInfoBtn: {
    fontSize: '18px',
    textTransform: 'none',
    marginLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'rgba(250, 250, 250, 0.3)'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px'
    }
  },
  footerInfoBtnIcon: {
    marginRight: theme.spacing(1.5),
    opacity: 0.5,
    fontSize: '26px !important'
  },
  footerLogoWrapper: {
    '& svg': {
      width: '125px',
      height: '60px'
    }
  },
  footerLogo: {
    marginRight: theme.spacing(2)
  },
  appStoresWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  appStoresText: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('sm')]: {
      display: 'none'
    }
  },
  appStoresLink: {
    display: 'inline-block'
  },
  appStoresImg: {
    margin: '0 4px',
    display: 'block'
  }
}))

const HpInfoSnackbar = props => {
  const classes = styles()

  return props.message ? (
    <div className={classes.footerInfoWrapper}>
      <div className={classes.footerInfoSnackbar}>
        {props.logo ? (
          <div className={classes.footerLogo}>
            <CustomSVG content={props.logo} className={classes.footerLogoWrapper} />
          </div>
        ) : null}
        <div className={classes.appStoresWrapper}>
          <Typography variant="subtitle2" className={classes.appStoresText}>
            {props.message}
          </Typography>

          {props.appleIcon?.storeIcon?.asset ? (
            <a
              href={props.appleIcon.downloadLink}
              className={classes.appStoresLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={props.appleIcon.storeIcon.asset.url}
                alt={props.appleIcon.alt}
                className={classes.appStoresImg}
              />
            </a>
          ) : null}
          {props.googleIcon?.storeIcon?.asset ? (
            <a
              href={props.googleIcon.downloadLink}
              className={classes.appStoresLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={props.googleIcon.storeIcon.asset.url}
                alt={props.googleIcon.alt}
                className={classes.appStoresImg}
              />
            </a>
          ) : null}
        </div>

        {props.link && props.linkText ? (
          <Link to={props.link} className={classes.footerInfoBtnLink}>
            <Button
              color="inherit"
              size="large"
              startIcon={<InfoOutlinedIcon className={classes.footerInfoBtnIcon} />}
              className={classes.footerInfoBtn}
            >
              {props.linkText}
            </Button>
          </Link>
        ) : null}
      </div>
    </div>
  ) : null
}

export default HpInfoSnackbar
